import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";
import { useCallback, useMemo } from "react";

export default function ParticlesComponent() {

    const options = useMemo(() => {
        return {
            interactivity: {
                detectsOn: "canvas",
                events: {
                  onHover: { enable: true, mode: "bubble" },
                  onClick: { enable: true, mode: "repulse" }
                //   resize: true
                },
                modes: {
                  bubble: { color: "#C3A359", distance: 100, duration: 2, opacity: 1, size: 10, speed: 3 },
                  repulse: { distance: 300 }
                }
              },
            particles: {
                color: {
                  value: "#ffffff",
                },
                links: {
                  color: "#ffffff",
                  distance: 150,
                  enable: false,
                  opacity: 0.5,
                  width: 1,
                },
                collisions: {
                  enable: false,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outMode: "out",
                  random: false,
                  speed: 0.7,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 800,
                  },
                  value: 180,
                },
                opacity: {
                  random: true,
                  value: 1,
                  anim: { enable: true, speed: 1, opacity_min: 0, sync: false }
                },
                shape: {
                  type: "circle",
                  stroke: { width: 0, color: '#000000' },
                  polygon: { nb_sides: 5 }
                },
                size: {
                  random: true,
                  value: 3,
                },
              },
        };
    }, []);

    const particlesInit = useCallback((engine) => {
        loadSlim(engine);
    }, []);

    return <Particles init={particlesInit} options={options} />
}