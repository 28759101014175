import logo from './logo.svg';
import image from './images/liminil_logo.png';
import image2 from './images/liminil_slogan.png';
import './App.css';
import ParticlesComponent from './components/Particles';

function App() {

  const sloganBottomText = '</*where the magic happens*/>'

  return (
    <div className="App">
      <header className="App-header">
        <img src={image} className="App-logo" alt="logo" />

        <div className='slogan-container' style={{ display: 'contents' }} >
          <div className='slogan-top' style={{ display: 'flex', marginTop: '30px' }} >

            <div style={{ marginRight: '5px' }} >
              <p style={{ fontFamily: 'optima' }}>
                The Space
              </p>
            </div>

            <div>
              <p style={{ fontFamily: 'optima', color: '#C3A75C' }}>
                |
              </p>
            </div>

            <div style={{ marginLeft: '5px' }} >
              <p style={{ fontFamily: 'optima' }}>
                In Between
              </p>
            </div>

          </div>

          <div className='slogan-bottom' style={{ marginTop: '-45px' }} >
              <p style={{ fontFamily: 'optima', color: '#C3A75C' }} >
                <bold><i>{sloganBottomText}</i></bold>
              </p>
          </div>

        </div>

        <div className='coming-soon' style={{ marginTop: '70px' }} >
          <p style={{ fontFamily: 'optima' }} >Launching Soon...</p>
        </div>


      </header>
      <ParticlesComponent />
    </div>
  );
}

export default App;
